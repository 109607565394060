<template>
  <v-container id="about-us" fluid>
    <v-row justify="center">
      <v-col cols="12" md="8">
       <v-card class="mt-0 px-5">
                <v-card-text>
                  <base-subheading subheading="About Us">
                    <p class="grey--text body-2 font-weight-light">
                      PAGE NOT FOUND
                    </p>
                  </base-subheading>
                    <v-btn to="/Dashboard">BACK</v-btn>
                </v-card-text>
              </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    name:'PageNotFound'
 
};
</script>
